<template>
  <div :class="['common_search', $route.name]" >
    <a-form 
      v-if="searchData.length > 0"
      ref="formRef" 
      :model="form"
      size="small">
        <a-form-item 
          :label-col-props="{ 'span': item.span || 5 }"
          :field="item.type == 'date' ? item.key1 : item.key[0]" 
          :label="item.title" 
          v-for="item in searchData"  
          :class="item.class"
          :style="{width: item.width}"
          :key="item.title">
          <a-input v-if="item.type == 'text'"  v-model="form[item.key]" :placeholder="item.place" @press-enter="searchClick" />
          <a-select 
            v-else-if="item.type == 'select'" 
            v-model="form[item.key]" 
            :multiple="item.multiple"
            :max-tag-count="item.multiple ? item.count || 0 : 0"
            :placeholder="item.place || '请选择'"
            :default-value=" typeof item.list == 'string' ? (item.value ? '' : 'ALL') : ''">
            <a-option v-for="item in (typeof item.list == 'string' ? CONST[item.list] : item.list)" :key="item.value" :value="item.value">{{ item.label }}</a-option>
          </a-select>
          <a-range-picker
            v-model="form[item.key1]"
            allow-clear
            v-else-if="item.type == 'date'"
            :style="{width: item.width}"
            @clear="clearClick"
          />
          <a-radio-group type="button" 
            v-model="form[item.key]" 
            v-else-if="item.type == 'radio'">
            {{ CONST[item.list] }}
            <a-radio v-for="item in CONST.newsTabList[item.list]" :value="item.type">{{ item.title }}</a-radio>
          </a-radio-group>
          </a-form-item>
    </a-form>
    <a-divider v-if="$route.name  == 'searchCenter'" direction="vertical" />
    <div class="button">
      <a-button type="primary" @click="searchClick"><svg-icon icon-class="search" />检索</a-button>
      <a-button type="outline" @click="resetClick"><svg-icon icon-class="eraser" />清空</a-button>
    </div>
  </div>
</template>

<script>
import { CONST } from '@/types/const'
export default {
  props: ['searchData', 'clearS'],
  data() {
    return {
      original: [],
      form: {},
      options: null
    }
  },
  computed: {
    CONST() {
      return CONST;
    },
  },
  watch: {
    clearS: {
      handler(n) {
        if(n) this.resetClick();
      },
      immediate: true
    },
    searchData: {
      handler(n) {
        if(n) {
          let data = {}
          this.searchData.forEach(item => {
            item.key.forEach((item1, index) => {
              data[item1] = item.type == 'select' ? 'ALL' : item.type == 'cascader' ? [] : item.type == 'radio' ? this.$route.params.type || 1 : (item.value || '')
            })
          })
          this.form = data;
        }
      },
      immediate: true
    },
    '$route.query.keyword': {
      handler(n) {
        if(n) {
          let reg = /^[-\s\/A-Za-z0-9]+$/g;
          if(reg.test(n)) {
            this.form.standardNumber = n;
            this.form.standardName = '';
          }else {
            this.form.standardName = n;
            this.form.standardNumber = '';
          }
          this.$emit('search', this.form)
        }
      },
      immediate: true
    },
    '$route.params.type': {
      handler(n) {
        if(n && this.$route.name == 'standardDynamics') {
          this.form.sort = n == 0 ? n : parseInt(n)
          this.$emit('search', this.form)
        }
      }, 
      immediate: true
    }
  },
  mounted() {
    this.searchData.forEach(item => {
      this.original.push(Object.assign({}, item));
    })
    if(this.$store.state.searchData) { 
      this.form = this.$store.state.searchData
    }
    if(this.$route.name == 'articleManagement') {
      this.articleOptionEvent()
    }
  },
  methods: {
    searchClick(type = null) {
      if(!type) return this.$emit('search', null)
      this.searchData.forEach(item => {
        if(item.type == 'date' && this.form[item.key1]  ) {
          item.key.forEach((item1, index) => {
            this.form[item1] = this.form[item.key1][index] || ''
          })
        }
      })
      this.$emit('search', this.form)
    },
    resetClick() {
      let keys = Object.keys(this.form)
      let num = 0;
      keys.forEach(item => {
        if(this.form[item]) this.form[item] = item == 'rangeValue' || item == 'rangeValue1' ? [] : ''
        else if(this.form[item] && typeof this.form[item] == 'object' && this.form[item].length > 0)  this.form[item] = item == 'rangeValue' ? [] : ''
        else if(item == 'sort') num++;
        else num++;
      })
      if(this.$refs.formRef) this.$refs.formRef.resetFields()
      if(num != keys.length ) this.searchClick(null);
      let data = {}
      this.searchData.forEach(item => {
        item.key.forEach((item1, index) => {
          data[item1] = item.type == 'select' ? 'ALL' : item.type == 'cascader' ? [] : item.type == 'radio' ? this.$route.params.type || 1 : ''
        })
      })
      this.form = data;
    },
    articleOptionEvent() {
      this.$API.AllArticleTypeList().then(res => {
        if(res.data.code == 200) {
          let options = [
            { value: 'A', key: 0, label: '政策法规', children: [] },
            { value: 'B', key: 1, label: '新闻通知', children: [] },
          ]
          options.forEach(item => {
            res.data.data.forEach(line => {
              if(item.key == line.parentClassification) {
                item.children.push({ value: line.id, label: line.classificationName })
              }
            })
          })
          this.options = options
        }
      })
    },
    clearClick() {
      this.searchData.forEach(item => {
        if(item.type == 'date') {
          item.key.forEach((item1, index) => {
            this.form[item1] = ''
          })
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .common_search {
    width: 100%;
    padding: 20px;
    background: #fafafa;
    display: flex;
    align-items: stretch;

    .button {
      .arco-btn {
        width: 80px;
        height: 32px;
        border-radius: 0px;
        margin-right: 10px;

        .svg-icon {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }

      }
    }

    .arco-form {
      flex-direction: initial;
      flex-wrap: wrap;
      width: auto;
    }

    .arco-form-item {
      margin-right: 25px;

      &:nth-child(3n) {
        margin-right: 20px;
      }
    }

    .arco-form-item-label-col {
      justify-content: flex-start
    }

    .arco-col-1 {
      flex: 0 0 82px;
      width: 82px;
      flex-shrink: 0;
      padding-right: 12px;
    }

    .arco-col-19 {
      flex: 0 0 240px;
      width: 240px;
      flex-shrink: 0;
    }

    .change {
      .arco-col-19 {
        flex: 0 0 263px;
        width: 263px;
        flex-shrink: 0;
      }
    }

    .change_2 {
      .arco-col-19 {
        flex: 0 0 336px;
        width: 336px;
        flex-shrink: 0;
      }
    }

    .arco-divider-vertical {
      margin: 0;
    }

    .no_b {
      margin-bottom: 0;
    }
  }


  .searchCenter {
    .arco-form {
      width: calc(100% - 100px);
    }

    .button {
      width: 80px;
      flex-shrink: 0;
      margin-left: 20px;
      margin-right: 0;
      position: relative;

      .arco-btn {
        position: absolute;
        display: block;
        margin-top: 20px;

        &:first-child {
          bottom: 52px;
        }

        &:last-child {
          bottom: 0;
        }
      }


      .arco-btn-outline {
        border: 1px solid #dcdfe6;
        font-size: 14px;
        color: #4e5969;
        padding: 0 15px;
      }
    }
  }

  .standardDynamics {
    .button {
      .arco-btn-size-medium {
        padding: 0 10px;
      }
    }
  }
</style>